//验证用户
export function checkAccount(account) {
  account = account.toString();
  if (!account.length) {
    return {status: false, msg: '请输入账号'};
  }
  if (account.match(/\D/) != null) {
    return {status: true, msg: ''}
  }
  return {status: false, msg: '账户错误，必须含有英文'};
}

export function checkNull(info) {
  info = info.toString();
  if (!info.length) {
    return {status: false, msg: '请正确填写信息'};
  }
  return {status: true, msg: ''}
}

//验证密码
export function checkPassword(password) {
  let str = password.toString();
  let isChinaese = /^[\u4e00-\u9fa5]+$/;
  if (!str.length) {
    return {status: false, msg: '请输入密码'}
  }
  if (str.length < 6 || isChinaese.test(str)) {
    return {status: false, msg: '密码不正确'}
  }
  return {status: true, msg: ''}
}

//验证手机号
export function checkPhone(phone) {
  let myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
  if (!phone || !phone.toString().length) {
    return false
  }
  if (!myreg.test(phone)) {
    return false
  }
  return true
}

//验证手机验证码
export function checkCode(code) {
  let myreg = /^[0-9]+.?[0-9]*$/;
  code = code.toString();
  if (!code.length) {
    return {status: false, msg: '请输入验证码'}
  }
  if (code.length != 6) {
    return {status: false, msg: '请输入正确的验证码'}
  }
  if (!myreg.test(code)) {
    return {status: false, msg: '验证码错误，请重新输入'}
  }
  return {status: true, msg: ''}
}

//验证邮箱
export function checkEmail(email) {
  let myreg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
  email = email.toString();
  if (!email.length) {
    return {status: false, msg: '请输入邮箱'}
  }
  if (!myreg.test(email)) {
    return {status: false, msg: '邮箱错误，请重新输入'}
  }
  return {status: true, msg: ''}
}

//验证码倒计时提示
export function getCodeTime(fn, finish) {
  let currentTime = 120;
  let showValue = currentTime + "秒";
  let interval = setInterval(function () {
    showValue = currentTime - 1 + "秒";
    currentTime--;
    if (currentTime <= 0) {
      clearInterval(interval);
      showValue = "获取验证码";
      finish();
    }
    fn(showValue);
  }, 1000);
}

export function checkObj(obj, checkItem) {
  for (let i = 0; i < (checkItem || []).length; i++) {
    let item = checkItem[i]
    let v = obj[item.value]
    if (!v || v.length === 0) {
      return {
        status: false,
        message: item.message,
      }
    }
  }
  return {status: true}
}

export function fillObj(obj, fill) {
  let keys = Object.keys(obj)
  for (let i = 0; i < keys.length; i++) {
    let val = fill[keys[i]]
    if (val) {
      obj[keys[i]] = val
    }
  }
}
