<template>
  <div class="login">
    <div class="header">
      <div class="logo">
        <a href="javascript:void(0);">
        </a>
      </div>
      <p>物联助力咖啡业务更高效</p>

      <p class="nav">
        <span>官网</span>
        <span>联系我们</span>
      </p>
    </div>

    <div class="login-section">
      <div class="banner">
        <img src="../../assets/img/login_banner.svg"/>
      </div>
      <div class="login-section-info info" v-if="isLogin" @click.stop.prevent>
        <p class="info-title">欢迎登录</p>
        <div class="info-content">
          <el-input ref="userNameInput" v-model="userInfo.phone" size="default" placeholder="请输入手机号" suffix-icon="el-icon-mobile-phone">
          </el-input>

          <el-input ref="userNameInput" v-model="userInfo.password" size="default" placeholder="请输入密码"
                    @keyup.enter.native="handleLogin"
                    :show-password="true">
          </el-input>

          <el-button class="user-btn" type="primary" @click="handleLogin">登录</el-button>
          <el-button type="text" size="small" @click="handleForgetVisible">忘记密码</el-button>
        </div>
      </div>
      <forget-password :close.sync="isLogin" v-show="!isLogin"></forget-password>
    </div>


    <p class="login-copyright">云咖信息系统集成服务©提供云计算服务️</p>
  </div>
</template>

<script>

  import {checkPhone} from "@/utils/check"
  import {setToken, getToken} from "@/utils/auth"

  export default {
    data() {
      return {
        isLogin: true,
        userInfo: {
          phone: '',
          password: ''
        },
        code: '',
      }
    },
    mounted: function () {
      let token = getToken()
      if (token) {
        window.location.href = '/'
      }
    },
    activated() {
      let token = getToken()
      if (token) {
        window.location.href = '/'
      }
    },
    methods: {
      login(data) {
        this.$api('account.prove.login', {},{code: this.code, orgUuid: data.orgUuid}).then(res => {
          setToken(res)
          window.location.href = '/'
        })
      },

      handleForgetVisible() {
        this.isLogin = false
      },
      handleLogin() {
        let ok = checkPhone(this.userInfo.phone)
        if (!ok || !this.userInfo.password) {
          this.$message.warn("请输入正确信息")
          return
        }

        this.$api('account.prove.getCode', {}, this.userInfo).then(data => {
          this.code = data
          this.$api('account.prove.orgs',{code: this.code}).then(data => {
            if (data.length === 1 && data[0].status === 3) {
              this.login(data[0])
            } else {
              console.log(data)
            }
          })
        })
      }
    },
    components: {
      ForgetPassword: function (resolve) {
        require(["./forget_password.vue"], resolve);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .login {
    background-image: url("../../assets/img/login_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      padding: $large-space;
      height: $header-height;
      background-color: rgba(22, 120, 254, .1);

      .logo {
        cursor: pointer;
        width: 136px;
        display: inline-block;
        vertical-align: middle;

        a {
          display: inline-block;
          width: 100%;
          /*margin: 10px 8px;*/
          height: 40px;
          background: url('../../assets/img/yunka_02_b.png') no-repeat;
          background-size: cover;
          position: relative;
          vertical-align: middle;
        }
      }

      p {
        margin-left: 20px;
        margin-bottom: 0;
        color: $color-white;
        font-size: 20px;
        font-weight: 500;
      }

      .nav {
        flex: 1;
        text-align: right;

        span {
          @include  font-medium-s();
          margin-right: $middle-space;
        }
      }
    }

    &-section {
      margin: auto;
      position: absolute;
      transform: translate(-50%, -50%);
      padding-top: 4%;
      top: 50%;
      left: 50%;
      width: 80%;
      height: 72%;
      max-height: 544px;
      display: flex;
      align-items: center;
      justify-items: center;

      .banner {
        margin-top: 4%;
        width: 48%;
        margin-right: 12%;
      }

      .el-input {
        margin-bottom: 24px;
      }

      ::v-deep .el-input {
        border-color: #e0e0e0;
      }



      ::v-deep .el-input, .el-input:focus {
         .el-input__inner {
          border-top: none !important;
          border-left: none;
          border-right: none;
          border-radius: 0;
          box-shadow: none;
        }
      }

      .user-btn {
        display: block;
        width: 250px;
        height: 45px;
        border: none;
        outline: none;
        letter-spacing: 5px;
        background: $theme-color;
        color: #fff;
        font-size: 14px;
        border-radius: 22.5px;
        cursor: pointer;
        margin-left: auto;
        margin-right: auto;
        margin-top: 32px;
        margin-bottom: $middle-space;
      }

      &-info {
        @include  board();
        height: 84%;
        max-height: 420px;
        flex: 1;
        border-radius: $box-radius;
        background-color: $color-white;
      }


      .info {
        padding: $large-space;
        max-width: 480px;
        margin: auto;

        &-title {
          @include  font-large();
          font-weight: 600;
          margin-top: 48px;
          margin-bottom: 32px;
          text-align: center;
          color: $theme-color;
        }

        &-content {
          margin: 0 32px;
          text-align: center;
        }
      }
    }


    &-copyright {
      width: 100%;
      color: $theme-light-6;
      position: absolute;

      @include font-little();
      bottom: $large-space;
      text-align: center;
    }
  }
</style>
